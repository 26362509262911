import React from 'react'

import styles from './Work.module.scss'

const WorkSVG = (props) => (
  <>
  <svg className={props.className || null} aria-hidden viewBox="0 0 36.42 8.64">
    <g>
      <path className={styles.cls1} d="M.11,8.38A.39.39,0,0,1,0,8.06L.4.42A.36.36,0,0,1,.51.21.35.35,0,0,1,.74.12H2.91A.46.46,0,0,1,3.2.19a.3.3,0,0,1,.07.25L3,3.83l.8-1.5A.57.57,0,0,1,4.24,2H5.46c.2,0,.32.12.34.35L6,3.82,7.11.44A.39.39,0,0,1,7.28.19.62.62,0,0,1,7.61.12H9.9a.19.19,0,0,1,.18.1.22.22,0,0,1,0,.1s0,.07,0,.1L7.49,8.06a.66.66,0,0,1-.24.32.66.66,0,0,1-.41.14H5.13A.4.4,0,0,1,4.7,8.2L4.06,5.74,2.73,8.2a.77.77,0,0,1-.2.21.56.56,0,0,1-.35.11H.46A.45.45,0,0,1,.11,8.38Z"/>
      <path className={styles.cls1} d="M11.57,8.33a3,3,0,0,1-1.3-.93,2.3,2.3,0,0,1-.49-1.47,2.89,2.89,0,0,1,.05-.53c.11-.63.25-1.34.45-2.11A4.31,4.31,0,0,1,11.76.83a4.82,4.82,0,0,1,3-.83,4.82,4.82,0,0,1,1.81.33,3.1,3.1,0,0,1,1.3,1,2.31,2.31,0,0,1,.49,1.48,2.89,2.89,0,0,1-.05.53c-.11.65-.26,1.36-.44,2.11a4.21,4.21,0,0,1-1.49,2.44,4.89,4.89,0,0,1-3,.8A5,5,0,0,1,11.57,8.33Zm3.17-3c.17-.69.31-1.36.43-2a1,1,0,0,0,0-.27A.71.71,0,0,0,15,2.51a.76.76,0,0,0-.52-.17,1.05,1.05,0,0,0-.69.24,1.32,1.32,0,0,0-.4.73c-.17.67-.32,1.34-.44,2a2.07,2.07,0,0,0,0,.29.62.62,0,0,0,.71.68A1.08,1.08,0,0,0,14.74,5.33Z"/>
      <path className={styles.cls1} d="M18.57,8.42a.25.25,0,0,1-.05-.22L20.16.44a.4.4,0,0,1,.15-.22.36.36,0,0,1,.25-.1h3.55a3.9,3.9,0,0,1,2.31.59,2,2,0,0,1,.81,1.67,3.23,3.23,0,0,1-.07.66,3.33,3.33,0,0,1-.64,1.37,4,4,0,0,1-1.15,1l.89,2.73a.23.23,0,0,1,0,.14.27.27,0,0,1-.12.18.32.32,0,0,1-.2.08H23.49a.42.42,0,0,1-.32-.1A.46.46,0,0,1,23,8.2L22.5,5.9H22l-.49,2.3a.38.38,0,0,1-.14.22.39.39,0,0,1-.26.1H18.77A.22.22,0,0,1,18.57,8.42Zm4.8-4.8a.61.61,0,0,0,.5-.24.84.84,0,0,0,.19-.55.56.56,0,0,0-.1-.35.42.42,0,0,0-.33-.13h-.87l-.27,1.27Z"/>
      <path className={styles.cls1} d="M27.06,8.42A.29.29,0,0,1,27,8.2L28.66.44A.38.38,0,0,1,28.8.22.34.34,0,0,1,29,.12h2.3a.25.25,0,0,1,.21.1.23.23,0,0,1,.06.16.13.13,0,0,1,0,.06l-.52,2.38L33,.38A.63.63,0,0,1,33.5.12h2.71a.21.21,0,0,1,.16.06.21.21,0,0,1,.05.16.32.32,0,0,1-.07.18L33.24,4.39l1.59,3.73a.89.89,0,0,1,0,.11.25.25,0,0,1-.11.21.37.37,0,0,1-.22.08H32a.42.42,0,0,1-.44-.28l-1-2.61L29.94,8.2a.41.41,0,0,1-.38.32H27.27A.24.24,0,0,1,27.06,8.42Z"/>
    </g>
  </svg>
  </>
)

export default WorkSVG
